import * as React from "react";
import Layout from "../components/layout";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { H1 } from "../styles/ui";

import { css } from "@emotion/react";

const NotFound: React.FC = () => {
  const wrongUrl =
    typeof window !== "undefined" ? window.location.pathname : "";
  let notFoundVariant: Variant = "index";
  let redirect = "";
  if (wrongUrl.includes("marketing")) {
    notFoundVariant = redirect = "marketing";
  }
  if (wrongUrl.includes("weboldal")) {
    notFoundVariant = redirect = "weboldal";
  }

  // React.useEffect(() => {
  //   console.log(wrongUrl);
  // }, [wrongUrl]);

  return (
    <Layout
      allowTransparentHeader={true}
      variant={notFoundVariant}
      title="404"
      description="Az oldal nem található"
    >
      <Wrapper
        css={css`
          color: ${notFoundVariant === "weboldal" ? "white" : "black"};
        `}
      >
        <div>
          <H1>404</H1>
          <h1>Hoppá...</h1>
          <h1>Úgy tűnik ez az oldal nem létezik.</h1>
          <Link to={`/${redirect}`}>
            <h1>Vissza a főoldalra</h1>
          </Link>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default NotFound;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: grid;
  place-items: center;

  text-align: center;

  a {
    color: var(--color-primary);
  }
`;
